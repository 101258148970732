import '../../../css/themes/base/main.css'
import preline from 'preline/dist/preline'
import { theme } from '../../../../app/helpers/theme_client_helper'
import Alpine from 'alpinejs'
import '../../htmx.js'
import 'htmx.org/dist/ext/response-targets'
import { changeTimezone } from '../../../../app/helpers/date_time_client_helper'
import resize from '@alpinejs/resize'

Alpine.plugin(resize)

document.addEventListener('alpine:init', () => {
  Alpine.store('activePath', window.location.pathname)
})

// If we are fetching a new page, set the active path so that the nav links are highlighted
document.addEventListener('htmx:beforeRequest', (event) => {
  if (event.detail.requestConfig.verb === 'get') {
    Alpine.store('activePath', event.detail.pathInfo.finalRequestPath)
  }
})

window.changeTimezone = changeTimezone
window.theme = theme
window.Alpine = Alpine

Alpine.start()
